import React from 'react';
import Pattern from './pattern';

export function Contact(){

  return (
    <div className="contact">
      <div className="contact-info">
        <div>
          <h3>Contact</h3>
          <a href="mailto:verona_elena@hotmail.fr" className="email"> verona_elena@hotmail.fr </a>
          <p className="location"> Paris 19e </p>
          <div className="social">
            <a href="https://www.linkedin.com/in/elena-verona-4a630077/" className="linkedin" target="_blank" rel="noreferrer" alt="linkedin"> </a>
            <a href="https://dribbble.com/elena01" className="dribble" target="_blank" rel="noreferrer" alt="dribble"> </a>
          </div>
        </div>
      </div>
      <Pattern />
    </div>
  );
}

export default Contact;
