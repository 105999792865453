import React from 'react';
import Squares from './squares';
import Pattern from './pattern';

export function Intro(){

  return (
    <div className="intro">
      <div>
        <div className="info">
          <h1>Elena Verona.</h1>
          <h2>Développeur Web +</h2>
          <h2>Front-End +</h2>
          <h2>ReactJs.</h2>
          <p>
            Hello ! Je m'appelle Elena, je suis développeur Web Front-End. Autodidacte et passionnée par le ReactJS,
            je m'interresse de près aux interfaces interactives ainsi qu'à l'expérience utilisateur.
            Je conçois et réalise des sites web du cahier des charges à la mise en ligne. <br></br>N'hésitez pas à me contacter !
          </p>
        </div>
        <Squares />
        <Pattern />
      </div>
    </div>
  );
}

export default Intro;
