import React from 'react';

export function Squares(){

  return (
    <div className="squares-content">
      <div className="squares block-one">
        <div className="square one" onMouseEnter={(e) => e.target.classList.toggle("active")}>
          <div className="circle big"></div>
          <div className="circle medium"></div>
          <div className="circle small"></div>
        </div>
        <div className="square two" onMouseEnter={(e) => e.target.classList.toggle("active")}>
          <div className="line big top"></div>
          <div className="line medium top"></div>
          <div className="line small"></div>
          <div className="line medium bottom"></div>
          <div className="line big bottom"></div>
        </div>
        <div className="square three" onMouseEnter={(e) => e.target.classList.toggle("active")}>
          <div className="triangle-shape triangle-circle"></div>
          <div className="triangle-shape triangle">
            <div></div>
          </div>
        </div>
        <div className="square four" onMouseEnter={(e) => e.target.classList.toggle("active")}>
          <div className="block outside top">
            <div className="block inside"></div>
          </div>
          <div className="block small"></div>
          <div className="block outside bottom">
            <div className="block inside"></div>
          </div>
        </div>
        <div className="square five" onMouseEnter={(e) => e.target.classList.toggle("active")}>
          <div className="block-simple outside top"></div>
          <div className="block-simple small top"></div>
          <div className="block-simple small bottom"></div>
          <div className="block-simple outside bottom"></div>
        </div>
      </div>
      <div className="squares block-two">
        <div className="square one" onMouseEnter={(e) => e.target.classList.toggle("active")}>
          <div className="block outside top">
            <div className="block inside"></div>
          </div>
          <div className="block small"></div>
          <div className="block outside bottom">
            <div className="block inside"></div>
          </div>
        </div>
        <div className="square two" onMouseEnter={(e) => e.target.classList.toggle("active")}>
          <div className="circle big"></div>
          <div className="circle medium"></div>
          <div className="circle small"></div>
        </div>
        <div className="square three" onMouseEnter={(e) => e.target.classList.toggle("active")}>
          <div className="triangle-shape triangle-circle"></div>
          <div className="triangle-shape triangle">
            <div></div>
          </div>
        </div>
        <div className="square four" onMouseEnter={(e) => e.target.classList.toggle("active")}>
          <div className="line big top"></div>
          <div className="line medium top"></div>
          <div className="line small"></div>
          <div className="line medium bottom"></div>
          <div className="line big bottom"></div>
        </div>
        <div className="square five" onMouseEnter={(e) => e.target.classList.toggle("active")}>
          <div className="block-simple outside top"></div>
          <div className="block-simple small top"></div>
          <div className="block-simple small bottom"></div>
          <div className="block-simple outside bottom"></div>
        </div>
      </div>
    </div>
  );
}

export default Squares;
