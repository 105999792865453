import React from "react";
import Intro from './components/intro';
import Projects from './components/projects';
import Contact from './components/contact';
import './styles/styles.scss';

function App() {
  return (
    <div className="App">
      <Intro />
      <Projects />
      <Contact />
    </div>
  );
}

export default App;
