import React from 'react';
export function Projects(){

  return (
    <div className="projects">
      <div className="item-projects">
        <a href="https://elenaverona.com/portfolio/" target="_blank" rel="noreferrer" alt="wordpress-folio">
          <div className="image">
            <div className="circle"></div>
          </div>
          <div className="info">
            <h3>Food & Colors</h3>
            <h4>ReactJS + Wordpress + Redux </h4>
            <div className="link">
              <p>Voir le projet</p>
              <div className="bg-link"></div>
            </div>
          </div>
        </a>
      </div>

      <div className="item-projects">
        <a href="https://elenaverona.com/shop/" target="_blank" rel="noreferrer" alt="e-shop">
          <div className="image">
            <div className="circle"></div>
          </div>
          <div className="info">
            <h3>E-shop</h3>
            <h4>ReactJS + Wordpress + Shopify + Redux </h4>
              <div className="link">
                <p>Voir le projet</p>
                <div className="bg-link"></div>
              </div>
          </div>
        </a>
      </div>

      <div className="item-projects">
        <a href="http://elenaverona.fr/" target="_blank" rel="noreferrer" alt="old-folio">
          <div className="image">
            <div className="circle"></div>
          </div>
          <div className="info">
            <h3>Old Folio</h3>
            <h4>ReactJS + Gastby + Transition-Link </h4>
            <div className="link">
              <p>Voir le projet</p>
              <div className="bg-link"></div>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
}

export default Projects;
